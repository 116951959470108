import React, { useState } from 'react'
import HeaderImage from '../assets/equipment-financing-bg.jpg' // You'll need to replace with appropriate image

const FundedDealsPage = () => {
    return (
        <div className='mt-[50px] min-h-screen bg-gray-50'>
            {/* Main Container */}
            <div className='max-w-full mx-auto'>
                {/* Title Section */}
                <div
                    className={`relative bg-cover bg-center h-[500px]`}
                    style={{ backgroundImage: `url(${HeaderImage})` }}
                >
                    <div className='absolute inset-0 bg-black bg-opacity-30 flex justify-center items-center'>
                        <h1 className='text-white text-5xl font-bold'>
                            Funded Deals
                        </h1>
                    </div>
                </div>

                {/* Main Grid */}
                <div className='grid grid-cols-1 lg:grid-cols-4 gap-8 mt-12 p-8'>
                    {/* Sidebar - Products */}
                    <div className='lg:col-span-1'>
                        <h2 className='text-2xl font-semibold'>Our Products</h2>
                        <ul className='space-y-2 mt-4'>
                            <li>
                                <a href='/sba-loans' className='text-blue-600'>
                                    SBA Loans
                                </a>
                            </li>
                            <li>
                                <a
                                    href='/lines-of-credit'
                                    className='text-blue-600'
                                >
                                    Lines of Credit
                                </a>
                            </li>
                            <li>
                                <a
                                    href='/equipment-financing'
                                    className='text-blue-600'
                                >
                                    Equipment Financing
                                </a>
                            </li>
                            <li>
                                <a
                                    href='/funded-deals'
                                    className='text-blue-600 font-bold'
                                >
                                    Funded Deals
                                </a>
                            </li>
                        </ul>
                    </div>

                    {/* Main Content */}
                    <div className='lg:col-span-3'>
                        {/* Funded Deals Information */}
                        <div>
                            <h2 className='text-2xl font-semibold'>
                                Recent Funded Deals
                            </h2>

                            {/* Deals Grid */}
                            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-8'></div>
                        </div>
                    </div>
                </div>

                {/* Statistics Section */}
                <div className='mt-12 p-8 bg-gray-100'>
                    <h2 className='text-2xl font-semibold'>
                        Funding Statistics
                    </h2>
                    <div className='mt-4 grid grid-cols-1 lg:grid-cols-3 gap-4'>
                        <div>
                            <h3 className='text-lg font-semibold'>
                                Total Funded
                            </h3>
                            <p>$0M+</p>
                        </div>
                        <div>
                            <h3 className='text-lg font-semibold'>
                                Businesses Helped
                            </h3>
                            <p>0,000+</p>
                        </div>
                        <div>
                            <h3 className='text-lg font-semibold'>
                                Average Deal Size
                            </h3>
                            <p>$0M</p>
                        </div>
                    </div>
                </div>

                {/* Industry Breakdown Section */}
                <div className='mt-12 p-8'>
                    <h2 className='text-2xl font-semibold'>
                        Industry Breakdown
                    </h2>
                    <div className='mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4'>
                        {/* Sample Industry Card - Repeat as needed */}
                        <div className='bg-white shadow rounded-md p-4'>
                            <h3 className='font-semibold'>Industry Name</h3>
                            <p className='text-gray-600 mt-2'>
                                XX% of portfolio
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FundedDealsPage
