import React, { useState } from 'react'
import axios from 'axios' // Make sure you have axios installed
import HeaderImage from '../assets/lines-of-credit-bg.jpg'
import { handleSendTextAlert } from '../utils/helpers'

const LinesOfCreditPage = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        businessName: '',
        email: '',
        phone: '',
        stateOfIncorporation: '',
        yearOfIncorporation: '',
        ficoScore: '',
    })
    const [validationErrors, setValidationErrors] = useState({})
    const [loading, setLoading] = useState(false)

    // Handle field change
    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData((prevState) => ({
            ...prevState,
            [name]: value.trim(), // Trim value to remove extra spaces
        }))
    }

    // Validate fields before submission
    const validateFields = () => {
        const errors = {}
        const phoneRegex = /^[0-9]{10}$/
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

        // Basic field checks
        if (!formData.firstName.trim()) {
            errors.firstName = 'First name is required.'
        }

        if (!formData.lastName.trim()) {
            errors.lastName = 'Last name is required.'
        }

        if (!formData.businessName.trim()) {
            errors.businessName = 'Business name is required.'
        }

        if (!formData.phone.trim()) {
            errors.phone = 'Phone number is required.'
        } else if (!phoneRegex.test(formData.phone)) {
            errors.phone =
                'Invalid phone number. Please enter a 10-digit number.'
        }

        if (!formData.email.trim()) {
            errors.email = 'Email is required.'
        } else if (!emailRegex.test(formData.email)) {
            errors.email = 'Invalid email format.'
        }

        if (!formData.annualRevenue) {
            errors.annualRevenue = 'Annual revenue is required.'
        }

        if (!formData.ficoScore) {
            errors.ficoScore = 'FICO score is required.'
        }

        setValidationErrors(errors)
        console.log('Validation Errors:', errors)

        // Return true if there are no errors
        return Object.keys(errors).length === 0
    }

    // Handle form submission
    const handleSubmitForm = async (e) => {
        e.preventDefault() // Prevent default form submission
        setValidationErrors({})

        const isValid = validateFields()
        if (!isValid) {
            return // If not valid, stop form submission
        }

        setLoading(true)

        const HUBSPOT_ENDPOINT = `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.REACT_APP_PORTAL_ID}/${process.env.REACT_APP_FORM_GUID}`

        const hubspotFormData = {
            fields: [
                { name: 'firstname', value: formData.firstName },
                { name: 'lastname', value: formData.lastName },
                { name: 'company', value: formData.businessName },
                { name: 'email', value: formData.email },
                { name: 'phone', value: formData.phone },
                { name: 'annual_revenue', value: formData.annualRevenue },
                { name: 'credit_score', value: formData.ficoScore },
            ],
        }

        try {
            await axios.post(HUBSPOT_ENDPOINT, hubspotFormData)

            await handleSendTextAlert(formData)

            setLoading(false)
            alert('Form submitted successfully!')
            setFormData({
                firstName: '',
                lastName: '',
                businessName: '',
                email: '',
                phone: '',
                stateOfIncorporation: '',
                yearOfIncorporation: '',
                ficoScore: '',
            })
        } catch (error) {
            console.error('Network error:', error)
            setLoading(false)
        }
    }

    return (
        <div className='mt-[50px] min-h-screen bg-gray-50'>
            {/* Main Container */}
            <div className='max-w-full mx-auto'>
                {/* Title Section */}
                <div
                    className={`relative bg-cover bg-center h-[500px]`}
                    style={{ backgroundImage: `url(${HeaderImage})` }}
                >
                    <div className='absolute inset-0 bg-black bg-opacity-30 flex justify-center items-center'>
                        <h1 className='text-white text-5xl font-bold'>
                            Lines of Credit
                        </h1>
                    </div>
                </div>

                {/* Main Grid */}
                <div className='grid grid-cols-1 lg:grid-cols-4 gap-8 mt-12 p-8'>
                    {/* Sidebar - Products */}
                    <div className='lg:col-span-1'>
                        <h2 className='text-2xl font-semibold'>Our Products</h2>
                        <ul className='space-y-2 mt-4'>
                            <li>
                                <a href='/sba-loans' className='text-blue-600'>
                                    SBA Loans
                                </a>
                            </li>
                            <li>
                                <a
                                    href='/lines-of-credit'
                                    className='text-blue-600 font-bold'
                                >
                                    Lines of Credit
                                </a>
                            </li>
                            <li>
                                <a
                                    href='/equipment-financing'
                                    className='text-blue-600'
                                >
                                    Equipment Financing
                                </a>
                            </li>
                        </ul>
                    </div>

                    {/* Main Content */}
                    <div className='lg:col-span-3 grid grid-cols-1 lg:grid-cols-2 gap-8'>
                        {/* Loan Information */}
                        <div>
                            <h2 className='text-2xl font-semibold'>
                                Lines of Credit
                            </h2>
                            <p className='mt-4 text-gray-600'>
                                One of the most significant challenges for small
                                businesses today is having access to capital at
                                a moment's notice. Our line of credit addresses
                                this issue by offering working capital funds
                                that can be drawn and paid back on a revolving
                                basis. Once approved, log into your online
                                account and draw funds as your business needs,
                                simply by clicking a button. The funds will
                                become available again as the account
                                replenishes with every payment. There are never
                                any maintenance fees or origination fees.
                                Moreover, the line will grow with your business!
                            </p>
                            <p className='mt-4 text-gray-600'>
                                The process is simple! When an unexpected bill,
                                purchase, or growth opportunity arises, simply
                                log in to your account and receive funds in
                                hours. No hassle! No waiting! No application!
                                You control the funds because you know what is
                                best for your business.
                            </p>
                        </div>

                        {/* Quick Quote Form */}
                        <div>
                            <h3 className='text-xl font-semibold'>
                                Quick Quote
                            </h3>
                            <form
                                onSubmit={handleSubmitForm}
                                className='space-y-4 mt-4'
                            >
                                <input
                                    className='w-full p-2 border rounded-md'
                                    type='text'
                                    name='firstName'
                                    placeholder='First Name'
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    required
                                />
                                <input
                                    className='w-full p-2 border rounded-md'
                                    type='text'
                                    name='lastName'
                                    placeholder='Last Name'
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    required
                                />
                                <input
                                    className='w-full p-2 border rounded-md'
                                    type='text'
                                    name='businessName'
                                    placeholder='Business Name'
                                    value={formData.businessName}
                                    onChange={handleChange}
                                    required
                                />
                                <input
                                    className='w-full p-2 border rounded-md'
                                    type='tel'
                                    name='phone'
                                    placeholder='Phone'
                                    value={formData.phone}
                                    onChange={handleChange}
                                    required
                                />
                                <input
                                    className='w-full p-2 border rounded-md'
                                    type='email'
                                    name='email'
                                    placeholder='Email'
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />

                                <select
                                    name='annualRevenue'
                                    className='w-full p-2 border rounded-md'
                                    value={formData.annualRevenue}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value=''>Annual Revenue</option>
                                    <option value='0-100000'>
                                        $0,000 - $100,000
                                    </option>
                                    <option value='100000-250000'>
                                        $100,000 - $250,000
                                    </option>
                                    <option value='250000-500000'>
                                        $250,000 - $500,000
                                    </option>
                                    <option value='500000-1000000'>
                                        $500,000 - $1,000,000
                                    </option>
                                    <option value='1000000-2500000'>
                                        $1,000,000 - $2,500,000
                                    </option>
                                    <option value='2500000-5000000'>
                                        $2,500,000 - $5,000,000
                                    </option>
                                    <option value='5000000-10000000'>
                                        $5,000,000 - $10,000,000
                                    </option>
                                    <option value='10000000+'>
                                        $10,000,000+
                                    </option>
                                </select>

                                <select
                                    className='w-full p-2 border rounded-md'
                                    name='ficoScore'
                                    value={formData.ficoScore}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value=''>Select FICO Score</option>
                                    <option value='550-639'>550-639</option>
                                    <option value='640-699'>640-699</option>
                                    <option value='700-749'>700-749</option>
                                    <option value='750+'>750+</option>
                                </select>
                                <button
                                    className='bg-blue-600 text-white p-2 rounded-md w-full'
                                    type='submit'
                                    disabled={loading}
                                >
                                    {loading ? 'Submitting...' : 'Submit'}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>

                {/* Benefits Section */}
                <div className='mt-12 p-8 bg-gray-100'>
                    <h2 className='text-2xl font-semibold'>Benefits</h2>
                    <div className='mt-4 grid grid-cols-1 lg:grid-cols-3 gap-4'>
                        <div>
                            <h3 className='text-lg font-semibold'>Terms</h3>
                            <p>3-24 Months</p>
                        </div>
                        <div>
                            <h3 className='text-lg font-semibold'>
                                Revolving Line
                            </h3>
                            <p>
                                Only pay on your outstanding balance and not
                                your loan amount.
                            </p>
                        </div>
                        <div>
                            <h3 className='text-lg font-semibold'>Unsecured</h3>
                            <p>
                                Completely unsecured with NO collateral needed.
                            </p>
                        </div>
                    </div>
                </div>

                {/* FAQ Section */}
                <div className='mt-12 p-8'>
                    <h2 className='text-2xl font-semibold'>
                        Frequently Asked Questions
                    </h2>
                    <div className='mt-4 space-y-4'>
                        {/* FAQ 1 */}
                        <details className='bg-white shadow rounded-md p-4'>
                            <summary className='font-semibold cursor-pointer hover:opacity-[80%]'>
                                How is qualification for a line of credit
                                determined?
                            </summary>
                            <p className='mt-2 text-gray-600'>
                                Qualification is determined upon review of
                                several factors. Some of the factors we consider
                                will include, but not be limited to, the cash
                                flow of your business, your industry, and the
                                strength of your customer base. We do look at
                                both personal and business credit, but this is
                                used to a lesser degree as a determining factor.
                                Keep in mind, we work with a large spectrum of
                                clients and your credit does not have to be
                                perfect, as many of our clients have strong
                                businesses with below-average credit.
                            </p>
                        </details>

                        {/* FAQ 2 */}
                        <details className='bg-white shadow rounded-md p-4'>
                            <summary className='font-semibold cursor-pointer hover:opacity-[80%]'>
                                How quickly does Anderson Kent Financial
                                Services make funding decisions?
                            </summary>
                            <p className='mt-2 text-gray-600'>
                                We do our best to respond in 20 minutes or less,
                                and we guarantee a response within 24 working
                                hours.
                            </p>
                        </details>

                        {/* FAQ 3 */}
                        <details className='bg-white shadow rounded-md p-4'>
                            <summary className='font-semibold cursor-pointer hover:opacity-[80%]'>
                                Are there termination fees if I want to leave?
                            </summary>
                            <p className='mt-2 text-gray-600'>
                                We would hate to see you go but if you must,
                                there is no termination fee charged. As long as
                                there is no outstanding balance, you can stop
                                using your line of credit at any point with no
                                penalties and no fees.
                            </p>
                        </details>

                        {/* FAQ 4 */}
                        <details className='bg-white shadow rounded-md p-4'>
                            <summary className='font-semibold cursor-pointer hover:opacity-[80%]'>
                                What is a business line of credit?
                            </summary>
                            <p className='mt-2 text-gray-600'>
                                A business line of credit is a funding line that
                                gives your business an opportunity to access
                                capital whenever you want, and up to your
                                approved credit limit. Anderson Kent Financial
                                Services offers revolving credit that
                                replenishes for access with every payment. This
                                is greatly different from one of those one-time
                                merchant cash advances or a traditional cash
                                flow loan, both of which are non-revolving.
                            </p>
                        </details>

                        {/* FAQ 5 */}
                        <details className='bg-white shadow rounded-md p-4'>
                            <summary className='font-semibold cursor-pointer hover:opacity-[80%]'>
                                How does repayment work?
                            </summary>
                            <p className='mt-2 text-gray-600'>
                                Monthly and weekly repayment programs are
                                available. The payments will be automatically
                                debited from your bank account. As long as you
                                keep sufficient funds in your bank, your
                                payments will process without you needing to
                                worry about anything.
                            </p>
                        </details>

                        {/* FAQ 6 */}
                        <details className='bg-white shadow rounded-md p-4'>
                            <summary className='font-semibold cursor-pointer hover:opacity-[80%]'>
                                How does repayment replenish my available
                                credit?
                            </summary>
                            <p className='mt-2 text-gray-600'>
                                Your line of credit will replenish as you repay
                                a draw, with the principal portion of your
                                payment (paid fees do not replenish the account)
                                being added back to your available credit. In
                                other words, if you have a weekly payment of
                                $150 with $10 in fees, your available credit
                                will increase by $140 after your payment is
                                processed.
                            </p>
                        </details>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LinesOfCreditPage
