import { Route, Router } from 'wouter'
import Footer from './components/Footer'
import Header from './components/Header'
import Home from './pages/Home'
import SBAFundingPage from './pages/SBAFunding'
import LinesOfCreditPage from './pages/LinesOfCredit'
import EquipmentFinancingPage from './pages/EquipmentFinancing'
import ContactUsPage from './pages/ContactUs'
import CareerOpportunities from './pages/CareerOpportunities'
import FundedDealsPage from './pages/FundedDeals'
const App = () => {
    return (
        <div className='app'>
            <Header />
            <Router>
                <Route path='/' component={Home} />
                <Route path='/sba-loans' component={SBAFundingPage} />
                <Route path='/lines-of-credit' component={LinesOfCreditPage} />
                <Route
                    path='/equipment-financing'
                    component={EquipmentFinancingPage}
                />
                <Route path='/careers' component={CareerOpportunities} />
                <Route path='/funded-deals' component={FundedDealsPage} />
                <Route path='/contact-us' component={ContactUsPage} />
            </Router>
            <Footer />
        </div>
    )
}

export default App
