import React from 'react'
import {
    FaStethoscope,
    FaLaptop,
    FaUserTie,
    FaShoppingBag,
    FaTruck,
    FaLeaf,
    FaDumbbell,
    FaBed,
    FaShieldAlt,
    FaBriefcase,
    FaPaintBrush,
} from 'react-icons/fa'

const industries = [
    { name: 'Accountants & Auditors', icon: FaUserTie },
    { name: 'Doctors & Medical Practices', icon: FaStethoscope },
    { name: 'Beauty Salons & Spas', icon: FaPaintBrush },
    { name: 'Construction & Contracting', icon: FaTruck },
    { name: 'Dentists & Dental Surgeons', icon: FaShieldAlt },
    { name: 'Advertising & Marketing Firms', icon: FaBriefcase },
    { name: 'E-Commerce or Internet Sales', icon: FaLaptop },
    { name: 'Landscaping Services', icon: FaLeaf },
    { name: 'Franchises', icon: FaShoppingBag },
    { name: 'Retail Stores', icon: FaShoppingBag },
    { name: 'Gymnasiums and Fitness Centers', icon: FaDumbbell },
    { name: 'Hotels & Lodging', icon: FaBed },
]

const IndustriesSection = () => {
    return (
        <div className='bg-blue-50 py-12'>
            <div className='max-w-7xl mx-auto'>
                {/* Industries Served Title */}
                <div className='text-center mb-12'>
                    <h2 className='text-3xl font-semibold text-gray-900'>
                        Industries Served
                    </h2>
                    <p className='text-gray-700 mt-4'>
                        Providing The Funding For Growth To Over 1000
                        Industries.
                    </p>
                </div>

                {/* Industries Grid */}
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-12'>
                    {industries.map((industry) => (
                        <div
                            key={industry.name}
                            className='flex flex-col items-center bg-white p-6 rounded-lg shadow-md'
                        >
                            <industry.icon className='w-12 h-12 text-blue-600 mb-4' />
                            <p className='text-gray-700'>{industry.name}</p>
                        </div>
                    ))}
                </div>

                {/* Get a Free Quote Section */}
                <div className='bg-blue-600 rounded-lg shadow-md p-8 text-center'>
                    <h3 className='text-xl font-semibold text-white mb-4'>
                        Are you looking for a business loan specific to your
                        industry?
                    </h3>
                    <a
                        className='bg-white text-blue-600 py-3 px-8 rounded-full mt-4 shadow-lg font-semibold hover:bg-black hover:text-white'
                        href='/contact-us'
                    >
                        Get a Free Quote
                    </a>
                </div>
            </div>
        </div>
    )
}

export default IndustriesSection
