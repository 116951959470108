import axios from 'axios'

export const handleSendTextAlert = async (formData) => {
    const textContent = `First Name: ${formData.firstName}\nLast Name: ${formData.lastName}\nBusiness Name: ${formData.businessName}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nAnnual Revenue: ${formData.annualRevenue}\nFICO Score: ${formData.ficoScore}`

    try {
        const response = await axios.post(
            'https://eo5f8vpyxhbm9ld.m.pipedream.net',
            {
                textContent,
            }
        )

        console.log('Text sent successfully:', response.data)
    } catch (error) {
        console.error('Error sending email:', error)
    }
}
