import React from 'react'
import { IoIosArrowForward } from 'react-icons/io'
import HeroImage from '../assets/hero-bg.jpg'

const HeroSection = () => {
    return (
        <section className='relative w-full h-screen overflow-hidden'>
            {/* Background image */}
            <div
                className='absolute inset-0 bg-cover bg-center'
                style={{ backgroundImage: `url(${HeroImage})` }}
            ></div>

            {/* Overlay */}
            <div className='absolute inset-0 bg-black opacity-50'></div>

            {/* Main Content */}
            <div className='relative z-10 flex flex-col items-start justify-center h-full max-w-7xl mx-auto px-8 space-y-6'>
                <h1 className='text-white text-6xl font-bold leading-tight tracking-tight animate__animated animate__fadeInUp'>
                    Helping Small Businesses Grow Big.
                </h1>

                <p className='text-white text-xl max-w-md leading-relaxed animate__animated animate__fadeInUp delay-1s'>
                    We provide loans, lines of credit, and financial solutions
                    to take your business to the next level.
                </p>

                <a
                    className='flex items-center gap-2 bg-blue-600 text-white py-3 px-6 rounded-full font-semibold text-lg hover:bg-black transition duration-300 animate__animated animate__fadeInUp delay-2s'
                    href='/sba-loans'
                >
                    See how we do it <IoIosArrowForward />
                </a>
            </div>
        </section>
    )
}

export default HeroSection
