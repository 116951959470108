import React from 'react';
import HeaderImage from '../assets/lines-of-credit-bg.jpg'; // Adjust the image path as needed

const CareerOpportunities = () => {
  return (
    <div className="mt-[50px] min-h-screen bg-gray-50">
      {/* Main Container */}
      <div className="max-w-full mx-auto">
        
        {/* Header Section */}
        <div 
          className={`relative bg-cover bg-center h-[500px]`}
          style={{ backgroundImage: `url(${HeaderImage})` }}
        >
          <div className="absolute inset-0 bg-black bg-opacity-30 flex justify-center items-center">
            <h1 className="text-white text-5xl font-bold">Career Opportunities</h1>
          </div>
        </div>

        {/* Career Opportunities Section */}
        <div className="mt-12 p-8">
          {/* Coming Soon Section */}
          <div className="text-center">
            <h2 className="text-4xl font-bold text-gray-900">Coming Soon: Exciting Career Opportunities</h2>
            <p className="mt-4 text-lg text-gray-600">
              We are continuously growing and will be adding more exciting opportunities in the near future. Check back regularly for updates!
            </p>
          </div>

          {/* Future Openings */}
          <div className="mt-12">
            <h3 className="text-2xl font-semibold text-gray-800">Future Openings:</h3>
            <ul className="mt-4 space-y-2 text-gray-700">
              <li>Loan Officers</li>
              <li>Loan Processors</li>
              <li>Sales Associates</li>
              <li>Inside Sales Representatives</li>
              <li>ISO Relationship Manager</li>
              <li>SBA Relationship Management Lead</li>
              <li>Associate, Underwriting</li>
              <li>Associate, Loan Servicing - Special Servicing</li>
            </ul>
          </div>

          {/* Join Our Team Section */}
          <div className="mt-12">
            <h3 className="text-2xl font-semibold text-gray-800">Join Our Team</h3>
            <p className="mt-4 text-lg text-gray-600">
              We're always on the lookout for talented individuals who are passionate about finance and customer service.
            </p>
          </div>

          {/* Equal Opportunity Employer Section */}
          <div className="mt-12 text-center">
            <p className="text-lg text-gray-600">
              AKFSI is an equal opportunity employer committed to diversity and inclusion in the workplace.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerOpportunities;
