import React, { useState } from 'react'
import axios from 'axios'
import { handleSendTextAlert } from '../utils/helpers'

const Footer = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        businessName: '',
        email: '',
        phone: '',
        stateOfIncorporation: '',
        yearOfIncorporation: '',
        ficoScore: '',
    })
    const [validationErrors, setValidationErrors] = useState({})
    const [loading, setLoading] = useState(false)

    // Handle field change
    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData((prevState) => ({
            ...prevState,
            [name]: value.trim(), // Trim value to remove extra spaces
        }))
    }

    // Validate fields before submission
    const validateFields = () => {
        const errors = {}
        const phoneRegex = /^[0-9]{10}$/
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

        // Basic field checks
        if (!formData.firstName.trim()) {
            errors.firstName = 'First name is required.'
        }

        if (!formData.lastName.trim()) {
            errors.lastName = 'Last name is required.'
        }

        if (!formData.businessName.trim()) {
            errors.businessName = 'Business name is required.'
        }

        if (!formData.phone.trim()) {
            errors.phone = 'Phone number is required.'
        } else if (!phoneRegex.test(formData.phone)) {
            errors.phone =
                'Invalid phone number. Please enter a 10-digit number.'
        }

        if (!formData.email.trim()) {
            errors.email = 'Email is required.'
        } else if (!emailRegex.test(formData.email)) {
            errors.email = 'Invalid email format.'
        }

        if (!formData.annualRevenue) {
            errors.annualRevenue = 'Annual revenue is required.'
        }

        if (!formData.ficoScore) {
            errors.ficoScore = 'FICO score is required.'
        }

        setValidationErrors(errors)
        console.log('Validation Errors:', errors)

        // Return true if there are no errors
        return Object.keys(errors).length === 0
    }

    // Handle form submission
    const handleSubmitForm = async (e) => {
        e.preventDefault() // Prevent default form submission
        setValidationErrors({})

        const isValid = validateFields()
        console.log(isValid)

        if (!isValid) {
            return // If not valid, stop form submission
        }

        setLoading(true)

        const HUBSPOT_ENDPOINT = `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.REACT_APP_PORTAL_ID}/${process.env.REACT_APP_FORM_GUID}`

        const hubspotFormData = {
            fields: [
                { name: 'firstname', value: formData.firstName },
                { name: 'lastname', value: formData.lastName },
                { name: 'company', value: formData.businessName },
                { name: 'email', value: formData.email },
                { name: 'phone', value: formData.phone },
                { name: 'annual_revenue', value: formData.annualRevenue },
                { name: 'credit_score', value: formData.ficoScore },
            ],
        }

        try {
            await axios.post(HUBSPOT_ENDPOINT, hubspotFormData)

            await handleSendTextAlert(formData)

            setLoading(false)
            alert('Form submitted successfully!')
            setFormData({
                firstName: '',
                lastName: '',
                businessName: '',
                email: '',
                phone: '',
                stateOfIncorporation: '',
                yearOfIncorporation: '',
                ficoScore: '',
            })
        } catch (error) {
            console.error('Network error:', error)
            setLoading(false)
        }
    }

    return (
        <footer className='bg-white py-10'>
            <div className='container mx-auto px-4'>
                <div className='grid md:grid-cols-4 gap-8'>
                    {/* Logo and Hours of Operation */}
                    <div className='col-span-1'>
                        <a href='' className='flex items-center'>
                            <img
                                src='https://akfsi.com/static/media/akfsi-logo-black.89678b5ba0a5b9122732.png'
                                alt='AKFSI Logo'
                                className='h-10'
                            />
                        </a>
                        <div className='mt-4'>
                            <p className='text-gray-600 font-medium'>
                                Hours of Operation
                            </p>
                            <p className='text-gray-500'>
                                Mon - Fri: 9:00AM - 6:00PM EST
                            </p>
                            <p className='text-gray-500'>Closed on Weekends</p>
                        </div>
                    </div>

                    {/* Menu Links */}
                    <div className='col-span-1'>
                        <h4 className='text-lg font-semibold text-gray-700'>
                            Menu
                        </h4>
                        <ul className='mt-4 space-y-2'>
                            <li>
                                <a
                                    href=''
                                    className='text-blue-600 hover:text-blue-800'
                                >
                                    Home
                                </a>
                            </li>
                            <li>
                                <a
                                    href='/sba-loans'
                                    className='text-gray-600 hover:text-blue-800'
                                >
                                    SBA Loans
                                </a>
                            </li>
                            <li>
                                <a
                                    href='/lines-of-credit'
                                    className='text-gray-600 hover:text-blue-800'
                                >
                                    Lines of Credit
                                </a>
                            </li>
                            <li>
                                <a
                                    href='/equipment-financing'
                                    className='text-gray-600 hover:text-blue-800'
                                >
                                    Equipment Financing
                                </a>
                            </li>
                            <li>
                                <a
                                    href='/contact-us'
                                    className='text-gray-600 hover:text-blue-800'
                                >
                                    Contact Us
                                </a>
                            </li>
                        </ul>
                    </div>

                    {/* Request a Call Back */}
                    <div className='col-span-2'>
                        <h4 className='text-lg font-semibold text-gray-700'>
                            Quick Quote
                        </h4>
                        <form
                            onSubmit={handleSubmitForm}
                            className='space-y-4 mt-4'
                        >
                            <div>
                                <input
                                    className='w-full p-2 border rounded-md'
                                    type='text'
                                    name='firstName'
                                    placeholder='First Name'
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    required
                                />
                                {validationErrors.firstName && (
                                    <p className='text-red-500'>
                                        {validationErrors.firstName}
                                    </p>
                                )}
                            </div>

                            <div>
                                <input
                                    className='w-full p-2 border rounded-md'
                                    type='text'
                                    name='lastName'
                                    placeholder='Last Name'
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    required
                                />
                                {validationErrors.lastName && (
                                    <p className='text-red-500'>
                                        {validationErrors.lastName}
                                    </p>
                                )}
                            </div>

                            <div>
                                <input
                                    className='w-full p-2 border rounded-md'
                                    type='text'
                                    name='businessName'
                                    placeholder='Business Name'
                                    value={formData.businessName}
                                    onChange={handleChange}
                                    required
                                />
                                {validationErrors.businessName && (
                                    <p className='text-red-500'>
                                        {validationErrors.businessName}
                                    </p>
                                )}
                            </div>

                            <div>
                                <input
                                    className='w-full p-2 border rounded-md'
                                    type='tel'
                                    name='phone'
                                    placeholder='Phone'
                                    value={formData.phone}
                                    onChange={handleChange}
                                    required
                                />
                                {validationErrors.phone && (
                                    <p className='text-red-500'>
                                        {validationErrors.phone}
                                    </p>
                                )}
                            </div>

                            <div>
                                <input
                                    className='w-full p-2 border rounded-md'
                                    type='email'
                                    name='email'
                                    placeholder='Email'
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                                {validationErrors.email && (
                                    <p className='text-red-500'>
                                        {validationErrors.email}
                                    </p>
                                )}
                            </div>

                            <div>
                                <select
                                    name='annualRevenue'
                                    className='w-full p-2 border rounded-md'
                                    value={formData.annualRevenue}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value=''>Annual Revenue</option>
                                    <option value='0-100000'>
                                        $0,000 - $100,000
                                    </option>
                                    <option value='100000-250000'>
                                        $100,000 - $250,000
                                    </option>
                                    <option value='250000-500000'>
                                        $250,000 - $500,000
                                    </option>
                                    <option value='500000-1000000'>
                                        $500,000 - $1,000,000
                                    </option>
                                    <option value='1000000-2500000'>
                                        $1,000,000 - $2,500,000
                                    </option>
                                    <option value='2500000-5000000'>
                                        $2,500,000 - $5,000,000
                                    </option>
                                    <option value='5000000-10000000'>
                                        $5,000,000 - $10,000,000
                                    </option>
                                    <option value='10000000+'>
                                        $10,000,000+
                                    </option>
                                </select>
                                {validationErrors.annualRevenue && (
                                    <p className='text-red-500'>
                                        {validationErrors.annualRevenue}
                                    </p>
                                )}
                            </div>

                            <div>
                                <select
                                    className='w-full p-2 border rounded-md'
                                    name='ficoScore'
                                    value={formData.ficoScore}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value=''>Select FICO Score</option>
                                    <option value='550-639'>550-639</option>
                                    <option value='640-699'>640-699</option>
                                    <option value='700-749'>700-749</option>
                                    <option value='750+'>750+</option>
                                </select>
                                {validationErrors.ficoScore && (
                                    <p className='text-red-500'>
                                        {validationErrors.ficoScore}
                                    </p>
                                )}
                            </div>

                            <button
                                className='bg-blue-600 text-white p-2 rounded-md w-full'
                                type='submit'
                                disabled={loading}
                            >
                                {loading ? 'Submitting...' : 'Submit'}
                            </button>
                        </form>
                    </div>
                </div>

                {/* Copyright */}
                <div className='mt-10 text-center text-gray-500'>
                    <p>
                        Copyright © {new Date().getFullYear()} AKFSI. All Rights
                        Reserved.{' '}
                        <a
                            href='/privacy'
                            className='text-blue-600 hover:underline'
                        >
                            Privacy Policy
                        </a>{' '}
                        |{' '}
                        <a
                            href='/terms'
                            className='text-blue-600 hover:underline'
                        >
                            Terms & Conditions
                        </a>
                    </p>
                </div>
            </div>
        </footer>
    )
}

export default Footer
