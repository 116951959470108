import React, { useState } from 'react'
import axios from 'axios' // Make sure you have axios installed
import HeaderImage from '../assets/equipment-financing-bg.jpg'
import { handleSendTextAlert } from '../utils/helpers'

const EquipmentFinancingPage = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        businessName: '',
        email: '',
        phone: '',
        stateOfIncorporation: '',
        yearOfIncorporation: '',
        ficoScore: '',
    })
    const [validationErrors, setValidationErrors] = useState({})
    const [loading, setLoading] = useState(false)

    // Handle field change
    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData((prevState) => ({
            ...prevState,
            [name]: value.trim(), // Trim value to remove extra spaces
        }))
    }

    // Validate fields before submission
    const validateFields = () => {
        const errors = {}
        const phoneRegex = /^[0-9]{10}$/
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

        // Basic field checks
        if (!formData.firstName.trim()) {
            errors.firstName = 'First name is required.'
        }

        if (!formData.lastName.trim()) {
            errors.lastName = 'Last name is required.'
        }

        if (!formData.businessName.trim()) {
            errors.businessName = 'Business name is required.'
        }

        if (!formData.phone.trim()) {
            errors.phone = 'Phone number is required.'
        } else if (!phoneRegex.test(formData.phone)) {
            errors.phone =
                'Invalid phone number. Please enter a 10-digit number.'
        }

        if (!formData.email.trim()) {
            errors.email = 'Email is required.'
        } else if (!emailRegex.test(formData.email)) {
            errors.email = 'Invalid email format.'
        }

        if (!formData.annualRevenue) {
            errors.annualRevenue = 'Annual revenue is required.'
        }

        if (!formData.ficoScore) {
            errors.ficoScore = 'FICO score is required.'
        }

        setValidationErrors(errors)
        console.log('Validation Errors:', errors)

        // Return true if there are no errors
        return Object.keys(errors).length === 0
    }

    // Handle form submission
    const handleSubmitForm = async (e) => {
        e.preventDefault() // Prevent default form submission
        setValidationErrors({})

        const isValid = validateFields()
        if (!isValid) {
            return // If not valid, stop form submission
        }

        setLoading(true)

        const HUBSPOT_ENDPOINT = `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.REACT_APP_PORTAL_ID}/${process.env.REACT_APP_FORM_GUID}`

        const hubspotFormData = {
            fields: [
                { name: 'firstname', value: formData.firstName },
                { name: 'lastname', value: formData.lastName },
                { name: 'company', value: formData.businessName },
                { name: 'email', value: formData.email },
                { name: 'phone', value: formData.phone },
                { name: 'annual_revenue', value: formData.annualRevenue },
                { name: 'credit_score', value: formData.ficoScore },
            ],
        }

        try {
            await axios.post(HUBSPOT_ENDPOINT, hubspotFormData)

            await handleSendTextAlert(formData)

            setLoading(false)
            alert('Form submitted successfully!')
            setFormData({
                firstName: '',
                lastName: '',
                businessName: '',
                email: '',
                phone: '',
                stateOfIncorporation: '',
                yearOfIncorporation: '',
                ficoScore: '',
            })
        } catch (error) {
            console.error('Network error:', error)
            setLoading(false)
        }
    }

    return (
        <div className='mt-[50px] min-h-screen bg-gray-50'>
            {/* Main Container */}
            <div className='max-w-full mx-auto'>
                {/* Title Section */}
                <div
                    className={`relative bg-cover bg-center h-[500px]`}
                    style={{ backgroundImage: `url(${HeaderImage})` }}
                >
                    <div className='absolute inset-0 bg-black bg-opacity-30 flex justify-center items-center'>
                        <h1 className='text-white text-5xl font-bold'>
                            Equipment Financing
                        </h1>
                    </div>
                </div>

                {/* Main Grid */}
                <div className='grid grid-cols-1 lg:grid-cols-4 gap-8 mt-12 p-8'>
                    {/* Sidebar - Products */}
                    <div className='lg:col-span-1'>
                        <h2 className='text-2xl font-semibold'>Our Products</h2>
                        <ul className='space-y-2 mt-4'>
                            <li>
                                <a href='/sba-loans' className='text-blue-600'>
                                    SBA Loans
                                </a>
                            </li>
                            <li>
                                <a
                                    href='/lines-of-credit'
                                    className='text-blue-600'
                                >
                                    Lines of Credit
                                </a>
                            </li>
                            <li>
                                <a
                                    href='/equipment-financing'
                                    className='text-blue-600 font-bold'
                                >
                                    Equipment Financing
                                </a>
                            </li>
                        </ul>
                    </div>

                    {/* Main Content */}
                    <div className='lg:col-span-3 grid grid-cols-1 lg:grid-cols-2 gap-8'>
                        {/* Equipment Financing Information */}
                        <div>
                            <h2 className='text-2xl font-semibold'>
                                Equipment Financing
                            </h2>
                            <p className='mt-4 text-gray-600'>
                                Purchasing new equipment can be a great strain
                                for a small business. Our equipment finance
                                products allow small business clients to
                                purchase the new equipment they need without
                                having to pay upfront. Our programs are spread
                                out with paybacks over 12-120 months. All credit
                                scores are considered, including challenged
                                credit and the approval decision are received
                                within a few hours. We can help you your
                                business purchase any type of equipment,
                                including construction equipment, machinery,
                                vehicles, software, furnishings, telecom
                                systems, specialized equipment and more!
                            </p>
                            <p className='mt-4 text-gray-600'>
                                When you work with Anderson Kent Financial
                                Services, you have access to a team of equipment
                                financing professionals dedicated to helping
                                businesses across the United States get the
                                equipment and technology they need to truly grow
                                and thrive. We help you keep up with your
                                competitors, even if they are better funded and
                                have greater access to capital.
                            </p>
                        </div>

                        {/* Quick Quote Form */}
                        <div>
                            <h3 className='text-xl font-semibold'>
                                Quick Quote
                            </h3>
                            <form
                                onSubmit={handleSubmitForm}
                                className='space-y-4 mt-4'
                            >
                                <input
                                    className='w-full p-2 border rounded-md'
                                    type='text'
                                    name='firstName'
                                    placeholder='First Name'
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    required
                                />
                                <input
                                    className='w-full p-2 border rounded-md'
                                    type='text'
                                    name='lastName'
                                    placeholder='Last Name'
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    required
                                />
                                <input
                                    className='w-full p-2 border rounded-md'
                                    type='text'
                                    name='businessName'
                                    placeholder='Business Name'
                                    value={formData.businessName}
                                    onChange={handleChange}
                                    required
                                />
                                <input
                                    className='w-full p-2 border rounded-md'
                                    type='tel'
                                    name='phone'
                                    placeholder='Phone'
                                    value={formData.phone}
                                    onChange={handleChange}
                                    required
                                />
                                <input
                                    className='w-full p-2 border rounded-md'
                                    type='email'
                                    name='email'
                                    placeholder='Email'
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />

                                <select
                                    name='annualRevenue'
                                    className='w-full p-2 border rounded-md'
                                    value={formData.annualRevenue}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value=''>Annual Revenue</option>
                                    <option value='0-100000'>
                                        $0,000 - $100,000
                                    </option>
                                    <option value='100000-250000'>
                                        $100,000 - $250,000
                                    </option>
                                    <option value='250000-500000'>
                                        $250,000 - $500,000
                                    </option>
                                    <option value='500000-1000000'>
                                        $500,000 - $1,000,000
                                    </option>
                                    <option value='1000000-2500000'>
                                        $1,000,000 - $2,500,000
                                    </option>
                                    <option value='2500000-5000000'>
                                        $2,500,000 - $5,000,000
                                    </option>
                                    <option value='5000000-10000000'>
                                        $5,000,000 - $10,000,000
                                    </option>
                                    <option value='10000000+'>
                                        $10,000,000+
                                    </option>
                                </select>

                                <select
                                    className='w-full p-2 border rounded-md'
                                    name='ficoScore'
                                    value={formData.ficoScore}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value=''>Select FICO Score</option>
                                    <option value='550-639'>550-639</option>
                                    <option value='640-699'>640-699</option>
                                    <option value='700-749'>700-749</option>
                                    <option value='750+'>750+</option>
                                </select>
                                <button
                                    className='bg-blue-600 text-white p-2 rounded-md w-full'
                                    type='submit'
                                    disabled={loading}
                                >
                                    {loading ? 'Submitting...' : 'Submit'}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>

                {/* Benefits Section */}
                <div className='mt-12 p-8 bg-gray-100'>
                    <h2 className='text-2xl font-semibold'>Benefits</h2>
                    <div className='mt-4 grid grid-cols-1 lg:grid-cols-3 gap-4'>
                        <div>
                            <h3 className='text-lg font-semibold'>Rates</h3>
                            <p>As low as 5.99%</p>
                        </div>
                        <div>
                            <h3 className='text-lg font-semibold'>Terms</h3>
                            <p>Options from 12-120 Months.</p>
                        </div>
                        <div>
                            <h3 className='text-lg font-semibold'>
                                Tax Benefits
                            </h3>
                            <p>
                                Structured Financing to Qualify for Section 179
                                Benefits
                            </p>
                        </div>
                    </div>
                </div>

                {/* FAQ Section */}
                <div className='mt-12 p-8'>
                    <h2 className='text-2xl font-semibold'>
                        Frequently Asked Questions
                    </h2>
                    <div className='mt-4 space-y-4'>
                        {/* FAQ 1 */}
                        <details className='bg-white shadow rounded-md p-4'>
                            <summary className='font-semibold cursor-pointer hover:opacity-[80%]'>
                                Will you hit my personal credit with an inquiry
                                when I apply?
                            </summary>
                            <p className='mt-2 text-gray-600'>
                                Nope. We can make a decision without an inquiry
                                showing up on your credit report.
                            </p>
                        </details>

                        {/* FAQ 2 */}
                        <details className='bg-white shadow rounded-md p-4'>
                            <summary className='font-semibold cursor-pointer hover:opacity-[80%]'>
                                Do you provide titled leases?
                            </summary>
                            <p className='mt-2 text-gray-600'>
                                Yes, we do – with a guaranteed purchase option.
                            </p>
                        </details>

                        {/* FAQ 3 */}
                        <details className='bg-white shadow rounded-md p-4'>
                            <summary className='font-semibold cursor-pointer hover:opacity-[80%]'>
                                Is your financing Section 179-friendly?
                            </summary>
                            <p className='mt-2 text-gray-600'>
                                Yes. And we encourage you to use Section 179 in
                                conjunction with our financing / equipment
                                leasing. Your accountant will be very pleased.
                            </p>
                        </details>

                        {/* FAQ 4 */}
                        <details className='bg-white shadow rounded-md p-4'>
                            <summary className='font-semibold cursor-pointer hover:opacity-[80%]'>
                                Once we apply, how fast is your approval
                                process?
                            </summary>
                            <p className='mt-2 text-gray-600'>
                                An approval is usually issued in as little as 24
                                hours. Your dedicated Account Manager will keep
                                you informed throughout the entire process.
                            </p>
                        </details>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EquipmentFinancingPage
