import React, { useState, useEffect } from 'react'
import { AiOutlineMail } from 'react-icons/ai'
import { FaBars, FaTimes } from 'react-icons/fa'

import Logo from '../assets/logo.png'

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false)
    const [showHeader, setShowHeader] = useState(true)
    const [lastScrollY, setLastScrollY] = useState(0)
    const location = window.location

    const toggleMenu = () => {
        setMenuOpen(!menuOpen)
    }

    const isActive = (path) => location.pathname === path

    const controlHeader = () => {
        if (window.scrollY > lastScrollY) {
            setShowHeader(false)
        } else {
            setShowHeader(true)
        }
        setLastScrollY(window.scrollY)
    }

    useEffect(() => {
        window.addEventListener('scroll', controlHeader)
        return () => {
            window.removeEventListener('scroll', controlHeader)
        }
    }, [lastScrollY])

    return (
        <header
            className={`fixed top-0 left-0 w-full z-50 bg-white shadow-md transition-transform duration-300 ${
                showHeader ? 'translate-y-0' : '-translate-y-full'
            }`}
        >
            <div className='max-w-7xl mx-auto px-4 py-3 flex items-center justify-between'>
                {/* Logo */}
                <a href='/' className='flex items-center'>
                    <img src={Logo} alt='AKFSI Logo' className='h-10' />
                </a>

                {/* Mobile Icons */}
                <div className='flex items-center space-x-4 md:hidden'>
                    <button
                        aria-label='Menu'
                        onClick={toggleMenu}
                        className='text-2xl text-gray-600'
                    >
                        {menuOpen ? <FaTimes /> : <FaBars />}
                    </button>
                </div>

                {/* Desktop Navigation */}
                <nav className='hidden md:flex space-x-8'>
                    <a
                        href='/'
                        className={`flex flex-col items-center font-medium hover:text-blue-600 transition duration-200 ${
                            isActive('/')
                                ? 'text-blue-600 font-bold'
                                : 'text-gray-800'
                        }`}
                    >
                        Home
                        {isActive('/') && (
                            <span className='w-6 h-[3px] bg-blue-600 mt-1 block rounded-full'></span>
                        )}
                    </a>
                    <a
                        href='/sba-loans'
                        className={`flex flex-col items-center font-medium hover:text-blue-600 transition duration-200 ${
                            isActive('/sba-loans')
                                ? 'text-blue-600 font-bold'
                                : 'text-gray-800'
                        }`}
                    >
                        SBA Loans
                        {isActive('/sba-loans') && (
                            <span className='w-6 h-[3px] bg-blue-600 mt-1 block rounded-full'></span>
                        )}
                    </a>
                    <a
                        href='/lines-of-credit'
                        className={`flex flex-col items-center font-medium hover:text-blue-600 transition duration-200 ${
                            isActive('/lines-of-credit')
                                ? 'text-blue-600 font-bold'
                                : 'text-gray-800'
                        }`}
                    >
                        Lines of Credit
                        {isActive('/lines-of-credit') && (
                            <span className='w-6 h-[3px] bg-blue-600 mt-1 block rounded-full'></span>
                        )}
                    </a>
                    <a
                        href='/equipment-financing'
                        className={`flex flex-col items-center font-medium hover:text-blue-600 transition duration-200 ${
                            isActive('/equipment-financing')
                                ? 'text-blue-600 font-bold'
                                : 'text-gray-800'
                        }`}
                    >
                        Equipment Financing
                        {isActive('/equipment-financing') && (
                            <span className='w-6 h-[3px] bg-blue-600 mt-1 block rounded-full'></span>
                        )}
                    </a>
                    <a
                        href='/funded-deals'
                        className={`flex flex-col items-center font-medium hover:text-blue-600 transition duration-200 ${
                            isActive('/funded-deals')
                                ? 'text-blue-600 font-bold'
                                : 'text-gray-800'
                        }`}
                    >
                        Funded Deals
                        {isActive('/funded-deals') && (
                            <span className='w-6 h-[3px] bg-blue-600 mt-1 block rounded-full'></span>
                        )}
                    </a>
                    <a
                        href='/contact-us'
                        className={`flex flex-col items-center font-medium hover:text-blue-600 transition duration-200 ${
                            isActive('/contact-us')
                                ? 'text-blue-600 font-bold'
                                : 'text-gray-800'
                        }`}
                    >
                        Contact Us
                        {isActive('/contact-us') && (
                            <span className='w-6 h-[3px] bg-blue-600 mt-1 block rounded-full'></span>
                        )}
                    </a>
                </nav>
            </div>

            {/* Mobile Menu Overlay */}
            <div
                className={`fixed inset-0 h-screen bg-white z-40 p-8 transition-all duration-500 ease-in-out transform ${
                    menuOpen
                        ? 'translate-x-0 opacity-100'
                        : 'translate-x-full opacity-0'
                }`}
            >
                {/* Close Button */}
                <button
                    aria-label='Close Menu'
                    onClick={toggleMenu}
                    className='absolute top-4 right-6 text-2xl text-gray-600'
                >
                    <FaTimes />
                </button>

                {/* Logo at the top */}
                <div className='flex justify-center mb-8'>
                    <img
                        src='https://akfsi.com/static/media/akfsi-logo-black.89678b5ba0a5b9122732.png'
                        alt='AKFSI Logo'
                        className='h-12'
                    />
                </div>

                {/* Menu items */}
                <div className='flex flex-col space-y-6 text-2xl font-bold text-black'>
                    <a
                        href='/'
                        className={`hover:text-blue-500 transition ${
                            isActive('/') ? 'text-blue-500' : ''
                        }`}
                    >
                        Home
                    </a>
                    <a
                        href='/sba-loans'
                        className={`hover:text-blue-500 transition ${
                            isActive('/sba-loans') ? 'text-blue-500' : ''
                        }`}
                    >
                        SBA Loans
                    </a>
                    <a
                        href='/lines-of-credit'
                        className={`hover:text-blue-500 transition ${
                            isActive('/lines-of-credit') ? 'text-blue-500' : ''
                        }`}
                    >
                        Lines of Credit
                    </a>
                    <a
                        href='/equipment-financing'
                        className={`hover:text-blue-500 transition ${
                            isActive('/equipment-financing')
                                ? 'text-blue-500'
                                : ''
                        }`}
                    >
                        Equipment Financing
                    </a>
                    <a
                        href='/funded-deals'
                        className={`hover:text-blue-500 transition ${
                            isActive('/funded-deals') ? 'text-blue-500' : ''
                        }`}
                    >
                        Funded Deals
                    </a>
                    <a
                        href='/contact-us'
                        className={`hover:text-blue-500 transition ${
                            isActive('/contact-us') ? 'text-blue-500' : ''
                        }`}
                    >
                        Contact Us
                    </a>
                </div>

                {/* Contact Information */}
                <div className='mt-12 text-lg text-center text-gray-700 bg-blue-600 p-4 rounded-lg'>
                    <p className='text-white'>
                        + Billion Funded to Small Businesses
                    </p>
                    <div className='flex justify-center mt-2'>
                        <AiOutlineMail className='text-white text-2xl mr-2' />
                        <p className='text-white'>funding@akfsi.com</p>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header
