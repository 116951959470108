import React, { useState } from 'react'
import { FaUserTie } from 'react-icons/fa'
import axios from 'axios'
import { handleSendTextAlert } from '../utils/helpers'

const RequestCallBack = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        businessName: '',
        email: '',
        phone: '',
        stateOfIncorporation: '',
        yearOfIncorporation: '',
        ficoScore: '',
    })
    const [validationErrors, setValidationErrors] = useState({})
    const [loading, setLoading] = useState(false)

    // Handle field change with validation for phone
    const handleChange = (e) => {
        const { name, value } = e.target
        if (name === 'phone') {
            const isIntegerOrEmpty = /^-?\d*$/.test(value)
            if (isIntegerOrEmpty) {
                setFormData((prevState) => ({
                    ...prevState,
                    [name]: value,
                }))
            }
        } else {
            setFormData((prevState) => ({
                ...prevState,
                [name]: value,
            }))
        }
    }

    // Validate fields before submission
    const validateFields = () => {
        const errors = {}
        if (!formData.firstName.trim()) {
            errors.firstName = 'First name is required.'
        }

        if (!formData.lastName.trim()) {
            errors.lastName = 'Last name is required.'
        }

        if (!formData.businessName.trim()) {
            errors.businessName = 'Business name is required.'
        }

        const phoneRegex = /^[0-9]{10}$/
        if (!formData.phone) {
            errors.phone = 'Phone number is required.'
        } else if (!phoneRegex.test(formData.phone)) {
            errors.phone =
                'Invalid phone number. Please enter a 10-digit number.'
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if (!formData.email) {
            errors.email = 'Email is required.'
        } else if (!emailRegex.test(formData.email)) {
            errors.email = 'Invalid email format.'
        }

        if (!formData.annualRevenue) {
            errors.annualRevenue = 'Annual revenue is required.'
        }

        if (!formData.ficoScore) {
            errors.ficoScore = 'FICO score is required.'
        }

        setValidationErrors(errors)
        return Object.keys(errors).length === 0 // Returns true if no errors
    }

    // Handle form submission
    const handleSubmitForm = async (e) => {
        e.preventDefault() // Prevent default form submission
        setValidationErrors({})

        const isValid = validateFields()
        if (!isValid) {
            return
        }

        if (
            formData.firstName &&
            formData.lastName &&
            formData.businessName &&
            formData.phone &&
            formData.email
        ) {
            setLoading(true)

            const HUBSPOT_ENDPOINT = `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.REACT_APP_PORTAL_ID}/${process.env.REACT_APP_FORM_GUID}`

            const hubspotFormData = {
                fields: [
                    {
                        name: 'firstname',
                        value: formData.firstName,
                    },
                    {
                        name: 'lastname',
                        value: formData.lastName,
                    },
                    {
                        name: 'company',
                        value: formData.businessName,
                    },
                    {
                        name: 'email',
                        value: formData.email,
                    },
                    {
                        name: 'phone',
                        value: formData.phone,
                    },
                    {
                        name: 'annual_revenue',
                        value: formData.annualRevenue,
                    },
                    {
                        name: 'credit_score',
                        value: formData.ficoScore,
                    },
                ],
            }

            try {
                await axios.post(HUBSPOT_ENDPOINT, hubspotFormData)

                await handleSendTextAlert(formData)

                setLoading(false)
                alert('Form submitted successfully!')
                setFormData({
                    firstName: '',
                    lastName: '',
                    businessName: '',
                    email: '',
                    phone: '',
                    stateOfIncorporation: '',
                    yearOfIncorporation: '',
                    ficoScore: '',
                })
            } catch (error) {
                console.error('Network error:', error)
                setLoading(false)
            }
        }
    }

    return (
        <div className='py-12 bg-white'>
            <div className='max-w-6xl mx-auto flex flex-col md:flex-row items-center justify-center'>
                {/* Left Side - Icon */}
                <div className='flex justify-center items-center flex-col mb-8 md:mb-0 md:mr-8'>
                    <FaUserTie className='text-blue-600 text-[120px]' />
                </div>

                {/* Right Side - Form */}
                <div className='w-full md:w-3/4 bg-gray-50 p-8 rounded-lg shadow-lg'>
                    <h2 className='text-4xl font-semibold text-gray-900 mb-4'>
                        Quick Quote
                    </h2>
                    <p className='text-gray-600 mb-8'>
                        We’re here to help you find the best funding option to
                        help grow your business, just tell us what you need.
                    </p>

                    <form
                        onSubmit={handleSubmitForm}
                        className='grid grid-cols-1 gap-6'
                    >
                        <input
                            type='text'
                            name='firstName'
                            placeholder='First Name'
                            value={formData.firstName}
                            onChange={handleChange}
                            className={`border border-gray-300 rounded-full p-4 shadow-sm focus:ring-2 focus:ring-blue-400 focus:outline-none transition ${
                                validationErrors.firstName
                                    ? 'border-red-500'
                                    : ''
                            }`}
                        />
                        {validationErrors.firstName && (
                            <p className='text-red-500'>
                                {validationErrors.firstName}
                            </p>
                        )}

                        <input
                            type='text'
                            name='lastName'
                            placeholder='Last Name'
                            value={formData.lastName}
                            onChange={handleChange}
                            className={`border border-gray-300 rounded-full p-4 shadow-sm focus:ring-2 focus:ring-blue-400 focus:outline-none transition ${
                                validationErrors.lastName
                                    ? 'border-red-500'
                                    : ''
                            }`}
                        />
                        {validationErrors.lastName && (
                            <p className='text-red-500'>
                                {validationErrors.lastName}
                            </p>
                        )}

                        <input
                            type='text'
                            name='businessName'
                            placeholder='Business Name'
                            value={formData.businessName}
                            onChange={handleChange}
                            className={`border border-gray-300 rounded-full p-4 shadow-sm focus:ring-2 focus:ring-blue-400 focus:outline-none transition ${
                                validationErrors.businessName
                                    ? 'border-red-500'
                                    : ''
                            }`}
                        />
                        {validationErrors.businessName && (
                            <p className='text-red-500'>
                                {validationErrors.businessName}
                            </p>
                        )}

                        <input
                            type='email'
                            name='email'
                            placeholder='Email'
                            value={formData.email}
                            onChange={handleChange}
                            className={`border border-gray-300 rounded-full p-4 shadow-sm focus:ring-2 focus:ring-blue-400 focus:outline-none transition ${
                                validationErrors.email ? 'border-red-500' : ''
                            }`}
                        />
                        {validationErrors.email && (
                            <p className='text-red-500'>
                                {validationErrors.email}
                            </p>
                        )}

                        <input
                            type='tel'
                            name='phone'
                            placeholder='Phone'
                            value={formData.phone}
                            onChange={handleChange}
                            className={`border border-gray-300 rounded-full p-4 shadow-sm focus:ring-2 focus:ring-blue-400 focus:outline-none transition ${
                                validationErrors.phone ? 'border-red-500' : ''
                            }`}
                        />
                        {validationErrors.phone && (
                            <p className='text-red-500'>
                                {validationErrors.phone}
                            </p>
                        )}

                        <select
                            name='annualRevenue'
                            className={`border border-gray-300 rounded-full p-4 shadow-sm focus:ring-2 focus:ring-blue-400 focus:outline-none transition ${
                                validationErrors.phone ? 'border-red-500' : ''
                            }`}
                            value={formData.annualRevenue}
                            onChange={handleChange}
                            required
                        >
                            <option value=''>Annual Revenue</option>
                            <option value='0-100000'>$0,000 - $100,000</option>
                            <option value='100000-250000'>
                                $100,000 - $250,000
                            </option>
                            <option value='250000-500000'>
                                $250,000 - $500,000
                            </option>
                            <option value='500000-1000000'>
                                $500,000 - $1,000,000
                            </option>
                            <option value='1000000-2500000'>
                                $1,000,000 - $2,500,000
                            </option>
                            <option value='2500000-5000000'>
                                $2,500,000 - $5,000,000
                            </option>
                            <option value='5000000-10000000'>
                                $5,000,000 - $10,000,000
                            </option>
                            <option value='10000000+'>$10,000,000+</option>
                        </select>
                        {validationErrors.annualRevenue && (
                            <p className='text-red-500'>
                                {validationErrors.annualRevenue}
                            </p>
                        )}

                        <select
                            name='ficoScore'
                            value={formData.ficoScore}
                            onChange={handleChange}
                            className='border border-gray-300 rounded-full p-4 shadow-sm focus:ring-2 focus:ring-blue-400 focus:outline-none transition'
                        >
                            <option value='default'>Select FICO Score</option>
                            <option value='550-639'>550-639</option>
                            <option value='640-699'>640-699</option>
                            <option value='700-749'>700-749</option>
                            <option value='750+'>750+</option>
                        </select>
                        {validationErrors.ficoScore && (
                            <p className='text-red-500'>
                                {validationErrors.ficoScore}
                            </p>
                        )}

                        <button
                            type='submit'
                            className='bg-blue-600 text-white py-3 rounded-full shadow-md hover:bg-blue-700 transition transform hover:scale-105 focus:ring-2 focus:ring-blue-400 focus:outline-none'
                            disabled={loading}
                        >
                            {loading ? 'Submitting...' : 'Get Started'}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default RequestCallBack
