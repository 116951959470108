import React, { useState } from 'react'
import axios from 'axios' // Ensure axios is installed
import HeaderImage from '../assets/contact-us-bg.jpg'
import { FaUserTie } from 'react-icons/fa'
import { handleSendTextAlert } from '../utils/helpers' // Import your text alert function

const ContactUsPage = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        businessName: '',
        email: '',
        phone: '',
        stateOfIncorporation: '',
        yearOfIncorporation: '',
        ficoScore: '',
    })
    const [validationErrors, setValidationErrors] = useState({})
    const [loading, setLoading] = useState(false)

    // Handle field change
    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData((prevState) => ({
            ...prevState,
            [name]: value.trim(), // Trim value to remove extra spaces
        }))
    }

    // Validate fields before submission
    const validateFields = () => {
        const errors = {}
        const phoneRegex = /^[0-9]{10}$/
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

        // Basic field checks
        if (!formData.firstName.trim()) {
            errors.firstName = 'First name is required.'
        }

        if (!formData.lastName.trim()) {
            errors.lastName = 'Last name is required.'
        }

        if (!formData.businessName.trim()) {
            errors.businessName = 'Business name is required.'
        }

        if (!formData.phone.trim()) {
            errors.phone = 'Phone number is required.'
        } else if (!phoneRegex.test(formData.phone)) {
            errors.phone =
                'Invalid phone number. Please enter a 10-digit number.'
        }

        if (!formData.email.trim()) {
            errors.email = 'Email is required.'
        } else if (!emailRegex.test(formData.email)) {
            errors.email = 'Invalid email format.'
        }

        if (!formData.annualRevenue) {
            errors.annualRevenue = 'Annual revenue is required.'
        }

        if (!formData.ficoScore) {
            errors.ficoScore = 'FICO score is required.'
        }

        setValidationErrors(errors)
        console.log('Validation Errors:', errors)

        // Return true if there are no errors
        return Object.keys(errors).length === 0
    }

    // Handle form submission
    const handleSubmitForm = async (e) => {
        e.preventDefault() // Prevent default form submission
        setValidationErrors({})

        const isValid = validateFields()
        console.log(isValid)

        if (!isValid) {
            return // If not valid, stop form submission
        }

        setLoading(true)

        const HUBSPOT_ENDPOINT = `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.REACT_APP_PORTAL_ID}/${process.env.REACT_APP_FORM_GUID}`

        const hubspotFormData = {
            fields: [
                { name: 'firstname', value: formData.firstName },
                { name: 'lastname', value: formData.lastName },
                { name: 'company', value: formData.businessName },
                { name: 'email', value: formData.email },
                { name: 'phone', value: formData.phone },
                { name: 'annual_revenue', value: formData.annualRevenue },
                { name: 'credit_score', value: formData.ficoScore },
            ],
        }

        try {
            await axios.post(HUBSPOT_ENDPOINT, hubspotFormData)

            await handleSendTextAlert(formData)

            setLoading(false)
            alert('Form submitted successfully!')
            setFormData({
                firstName: '',
                lastName: '',
                businessName: '',
                email: '',
                phone: '',
                stateOfIncorporation: '',
                yearOfIncorporation: '',
                ficoScore: '',
            })
        } catch (error) {
            console.error('Network error:', error)
            setLoading(false)
        }
    }

    return (
        <div className='mt-[50px] min-h-screen bg-gray-50'>
            {/* Main Container */}
            <div className='max-w-full mx-auto'>
                {/* Title Section */}
                <div
                    className={`relative bg-cover bg-center h-[500px]`}
                    style={{ backgroundImage: `url(${HeaderImage})` }}
                >
                    <div className='absolute inset-0 bg-black bg-opacity-30 flex justify-center items-center'>
                        <h1 className='text-white text-5xl font-bold'>
                            Contact Us
                        </h1>
                    </div>
                </div>

                {/* Main Grid */}
                <div className='grid grid-cols-1 lg:grid-cols-4 gap-8 mt-12 p-8'>
                    {/* Sidebar - Products */}
                    <div className='lg:col-span-1'>
                        <h2 className='text-2xl font-semibold'>Our Products</h2>
                        <ul className='space-y-2 mt-4'>
                            <li>
                                <a href='/sba-loans' className='text-blue-600'>
                                    SBA Loans
                                </a>
                            </li>
                            <li>
                                <a
                                    href='/lines-of-credit'
                                    className='text-blue-600'
                                >
                                    Lines of Credit
                                </a>
                            </li>
                            <li>
                                <a
                                    href='/equipment-financing'
                                    className='text-blue-600'
                                >
                                    Equipment Financing
                                </a>
                            </li>
                        </ul>
                    </div>

                    {/* Main Content */}
                    <div className='lg:col-span-3'>
                        {/* Contact Form */}
                        <div>
                            <h3 className='text-2xl font-semibold'>
                                Quick Quote.
                            </h3>
                            <>
                                <p>
                                    Please fill out the form below and we will
                                    be in touch shortly.
                                </p>
                                <form
                                    onSubmit={handleSubmitForm}
                                    className='space-y-4 mt-4'
                                >
                                    <div>
                                        <input
                                            className='w-full p-2 border rounded-md'
                                            type='text'
                                            name='firstName'
                                            placeholder='First Name'
                                            value={formData.firstName}
                                            onChange={handleChange}
                                            required
                                        />
                                        {validationErrors.firstName && (
                                            <p className='text-red-500'>
                                                {validationErrors.firstName}
                                            </p>
                                        )}
                                    </div>

                                    <div>
                                        <input
                                            className='w-full p-2 border rounded-md'
                                            type='text'
                                            name='lastName'
                                            placeholder='Last Name'
                                            value={formData.lastName}
                                            onChange={handleChange}
                                            required
                                        />
                                        {validationErrors.lastName && (
                                            <p className='text-red-500'>
                                                {validationErrors.lastName}
                                            </p>
                                        )}
                                    </div>

                                    <div>
                                        <input
                                            className='w-full p-2 border rounded-md'
                                            type='text'
                                            name='businessName'
                                            placeholder='Business Name'
                                            value={formData.businessName}
                                            onChange={handleChange}
                                            required
                                        />
                                        {validationErrors.businessName && (
                                            <p className='text-red-500'>
                                                {validationErrors.businessName}
                                            </p>
                                        )}
                                    </div>

                                    <div>
                                        <input
                                            className='w-full p-2 border rounded-md'
                                            type='tel'
                                            name='phone'
                                            placeholder='Phone'
                                            value={formData.phone}
                                            onChange={handleChange}
                                            required
                                        />
                                        {validationErrors.phone && (
                                            <p className='text-red-500'>
                                                {validationErrors.phone}
                                            </p>
                                        )}
                                    </div>

                                    <div>
                                        <input
                                            className='w-full p-2 border rounded-md'
                                            type='email'
                                            name='email'
                                            placeholder='Email'
                                            value={formData.email}
                                            onChange={handleChange}
                                            required
                                        />
                                        {validationErrors.email && (
                                            <p className='text-red-500'>
                                                {validationErrors.email}
                                            </p>
                                        )}
                                    </div>

                                    <div>
                                        <select
                                            name='annualRevenue'
                                            className='w-full p-2 border rounded-md'
                                            value={formData.annualRevenue}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value=''>
                                                Annual Revenue
                                            </option>
                                            <option value='0-100000'>
                                                $0,000 - $100,000
                                            </option>
                                            <option value='100000-250000'>
                                                $100,000 - $250,000
                                            </option>
                                            <option value='250000-500000'>
                                                $250,000 - $500,000
                                            </option>
                                            <option value='500000-1000000'>
                                                $500,000 - $1,000,000
                                            </option>
                                            <option value='1000000-2500000'>
                                                $1,000,000 - $2,500,000
                                            </option>
                                            <option value='2500000-5000000'>
                                                $2,500,000 - $5,000,000
                                            </option>
                                            <option value='5000000-10000000'>
                                                $5,000,000 - $10,000,000
                                            </option>
                                            <option value='10000000+'>
                                                $10,000,000+
                                            </option>
                                        </select>
                                        {validationErrors.annualRevenue && (
                                            <p className='text-red-500'>
                                                {validationErrors.annualRevenue}
                                            </p>
                                        )}
                                    </div>

                                    <div>
                                        <select
                                            className='w-full p-2 border rounded-md'
                                            name='ficoScore'
                                            value={formData.ficoScore}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value=''>
                                                Select FICO Score
                                            </option>
                                            <option value='550-639'>
                                                550-639
                                            </option>
                                            <option value='640-699'>
                                                640-699
                                            </option>
                                            <option value='700-749'>
                                                700-749
                                            </option>
                                            <option value='750+'>750+</option>
                                        </select>
                                        {validationErrors.ficoScore && (
                                            <p className='text-red-500'>
                                                {validationErrors.ficoScore}
                                            </p>
                                        )}
                                    </div>

                                    <button
                                        className='bg-blue-600 text-white p-2 rounded-md w-full'
                                        type='submit'
                                        disabled={loading}
                                    >
                                        {loading ? 'Submitting...' : 'Submit'}
                                    </button>
                                </form>
                            </>
                        </div>

                        {/* Contact Info */}
                        <div className='mt-8 flex items-center'>
                            <div className='flex items-center space-x-2'>
                                <div className='p-4 rounded-full bg-blue-600 text-white'>
                                    <FaUserTie size={25} />
                                </div>
                                <div>
                                    <p className='font-semibold'>
                                        Contact Email
                                    </p>
                                    <p>jordan@akfsi.com</p>
                                </div>
                            </div>
                        </div>

                        {/* Google Map */}
                        <div className='mt-12'>
                            <iframe
                                className='w-full h-[500px] border-0'
                                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5506.578429618178!2d-73.53440429999999!3d40.789474899999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2816ab67d73af%3A0x46c0efbd968c25a6!2s471%20N%20Broadway%2C%20Jericho%2C%20NY%2011753%2C%20USA!5e1!3m2!1sen!2s!4v1729543256753!5m2!1sen!2s'
                                allowFullScreen=''
                                loading='lazy'
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUsPage
